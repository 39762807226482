/* .btn:hover,
.btn:focus,
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus,
.btn:active:hover,
.btn.active:hover,
.navbar .navbar-nav>a.btn:hover,
.navbar .navbar-nav>a.btn:focus,
.navbar .navbar-nav>a.btn:not(:disabled):not(.disabled):active,
.navbar .navbar-nav>a.btn:not(:disabled):not(.disabled).active,
.navbar .navbar-nav>a.btn:not(:disabled):not(.disabled):active:focus,
.navbar .navbar-nav>a.btn:not(:disabled):not(.disabled).active:focus,
.navbar .navbar-nav>a.btn:active:hover,
.navbar .navbar-nav>a.btn.active:hover {
    background-color: #979797;
    color: #FFFFFF;
    box-shadow: none;
} */

.hide-desktop {
    display: none;
  }
  .hide-mobile {
    display: none;
  }
  
  .btn:hover,
  .navbar .navbar-nav > a.btn:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
  }
  
  .btn:disabled,
  .btn:disabled:hover,
  .btn:disabled:focus,
  .btn:disabled:active,
  .btn:disabled.active,
  .btn[disabled],
  .btn[disabled]:hover,
  .btn[disabled]:focus,
  .btn[disabled]:active,
  .btn[disabled].active,
  .navbar .navbar-nav > a.btn:disabled,
  .navbar .navbar-nav > a.btn:disabled:hover,
  .navbar .navbar-nav > a.btn:disabled:focus,
  .navbar .navbar-nav > a.btn:disabled:active,
  .navbar .navbar-nav > a.btn:disabled.active,
  .navbar .navbar-nav > a.btn[disabled],
  .navbar .navbar-nav > a.btn[disabled]:hover,
  .navbar .navbar-nav > a.btn[disabled]:focus,
  .navbar .navbar-nav > a.btn[disabled]:active,
  .navbar .navbar-nav > a.btn[disabled].active {
    background-color: #888888;
    border-color: #888888;
  }
  
  .btn:hover,
  .btn:focus,
  .navbar .navbar-nav > a.btn:hover,
  .navbar .navbar-nav > a.btn:focus {
    opacity: 1;
    filter: alpha(opacity=100);
    outline: 0 !important;
  }
  
  .btn:active,
  .btn.active,
  .navbar .navbar-nav > a.btn:active,
  .navbar .navbar-nav > a.btn.active {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important;
  }
  
  .btn.btn-icon,
  .navbar .navbar-nav > a.btn.btn-icon {
    height: 2.375rem;
    min-width: 2.375rem;
    width: 2.375rem;
    padding: 0;
    font-size: 0.9375rem;
    overflow: hidden;
    position: relative;
    line-height: normal;
  }
  
  .btn.btn-icon.btn-lg,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg {
    height: 3.6rem;
    min-width: 3.6rem;
    width: 3.6rem;
  }
  
  .btn.btn-icon.btn-lg i.now-ui-icons,
  .btn.btn-icon.btn-lg i.fab,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg i.now-ui-icons,
  .navbar .navbar-nav > a.btn.btn-icon.btn-lg i.fab {
    font-size: 1.325rem;
  }
  
  .btn.btn-icon:not(.btn-footer) i.now-ui-icons,
  .btn.btn-icon:not(.btn-footer) i.fab,
  .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) i.now-ui-icons,
  .navbar .navbar-nav > a.btn.btn-icon:not(.btn-footer) i.fab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-12px, -12px);
    line-height: 1.5626rem;
    width: 23px;
  }
  
  .btn:not(.btn-icon) .now-ui-icons,
  .navbar .navbar-nav > a.btn:not(.btn-icon) .now-ui-icons {
    position: relative;
    top: 1px;
  }
  
  .btn-primary {
    background-color: #f96332;
    color: #ffffff;
  }
  
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:active:hover,
  .btn-primary.active:hover {
    background-color: #fa7a50;
    color: #ffffff;
    box-shadow: none;
  }
  
  .btn-primary:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
  }
  
  .btn-primary:disabled,
  .btn-primary:disabled:hover,
  .btn-primary:disabled:focus,
  .btn-primary:disabled:active,
  .btn-primary:disabled.active,
  .btn-primary[disabled],
  .btn-primary[disabled]:hover,
  .btn-primary[disabled]:focus,
  .btn-primary[disabled]:active,
  .btn-primary[disabled].active {
    background-color: #f96332;
    border-color: #f96332;
  }
  
  .btn-round {
    border-width: 1px;
    border-radius: 30px !important;
    padding: 11px 23px;
  }
  
  .btn-lg {
    font-size: 1em;
    border-radius: 0.25rem;
    padding: 15px 48px;
  }
  
  button,
  input {
    font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
  }
  
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
  }
  
  a {
    color: #f96332;
  }
  
  a:hover,
  a:focus {
    color: #f96332;
  }
  
  h2,
  .h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
  }
  
  h3,
  .h3 {
    font-size: 1.825em;
    margin-bottom: 30px;
    line-height: 1.4em;
  }
  
  h4,
  .h4 {
    font-size: 1.5em;
    line-height: 1.45em;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  h4 + .category,
  h4.title + .category,
  .h4 + .category,
  .h4.title + .category {
    margin-top: -5px;
  }
  
  h5,
  .h5 {
    font-size: 1.3em;
    line-height: 1.4em;
    margin-bottom: 15px;
  }
  
  h5.category,
  .h5.category {
    font-weight: 400;
  }
  
  p {
    line-height: 1.61em;
    font-weight: 300;
    font-size: 1.2em;
  }
  
  .title {
    font-weight: 700;
    padding-top: 30px;
  }
  
  .title + .category {
    margin-top: -25px;
  }
  
  .description {
    color: #9a9a9a;
    font-weight: 300;
  }
  
  .category {
    text-transform: capitalize;
    font-weight: 700;
    color: #9a9a9a;
  }
  
  
  .nav-pills .nav-link,
  .nav-item .nav-link,
  .navbar {
    -webkit-transition: all 300ms ease 0s;
    -moz-transition: all 300ms ease 0s;
    -o-transition: all 300ms ease 0s;
    -ms-transition: all 300ms ease 0s;
    transition: all 300ms ease 0s;
  }
  
  .dropdown-toggle:after,
  [data-toggle='collapse'][data-parent='#accordion'] i {
    -webkit-transition: transform 150ms ease 0s;
    -moz-transition: transform 150ms ease 0s;
    -o-transition: transform 150ms ease 0s;
    -ms-transition: all 150ms ease 0s;
    transition: transform 150ms ease 0s;
  }
  
  .dropdown-toggle[aria-expanded='true']:after,
  [data-toggle='collapse'][data-parent='#accordion'][aria-expanded='true'] i {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .button-bar {
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: #ffffff;
  }
  
  .button-bar + .button-bar {
    margin-top: 7px;
  }
  
  .button-bar:nth-child(2) {
    width: 17px;
  }
  
  .nav-pills.nav-pills-just-icons .nav-item .nav-link {
    text-align: center;
    border-radius: 50%;
    height: 80px;
    width: 80px;
    padding: 0;
    max-width: 80px;
    min-width: auto;
    margin-bottom: 4px;
  }
  
  .nav-pills.nav-pills-just-icons .nav-item .nav-link i {
    line-height: 80px;
  }
  
  .nav-pills:not(.flex-column) .nav-item:not(:last-child) .nav-link {
    margin-right: 19px;
  }
  
  .nav-pills .nav-item .nav-link {
    padding: 10px 23px;
    background-color: rgba(222, 222, 222, 0.3);
    min-width: 100px;
    font-weight: 400;
    text-align: center;
    color: #444;
  }
  
  .nav-pills .nav-item .nav-link:hover {
    background-color: rgba(222, 222, 222, 0.3);
  }
  
  .nav-pills .nav-item .nav-link.active,
  .nav-pills .nav-item .nav-link.active:focus,
  .nav-pills .nav-item .nav-link.active:hover {
    background-color: #9a9a9a;
    color: #ffffff;
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
  }
  
  .nav-pills .nav-item .nav-link:disabled,
  .nav-pills .nav-item .nav-link[disabled] {
    opacity: 0.5;
  }
  
  .nav-pills .nav-item i {
    display: block;
    line-height: 60px;
    font-size: 24px;
  }
  
  .nav-pills.nav-pills-primary .nav-item .nav-link.active,
  .nav-pills.nav-pills-primary .nav-item .nav-link.active:focus,
  .nav-pills.nav-pills-primary .nav-item .nav-link.active:hover {
    background-color: #f96332;
  }
  
  .nav-align-center {
    text-align: center;
  }
  
  .nav-align-center .nav-pills {
    display: inline-flex;
  }
  
  .now-ui-icons {
    display: inline-block;
    font: normal normal normal 14px/1 'Nucleo Outline';
    font-size: inherit;
    speak: none;
    text-transform: none;
  }
  
  @-webkit-keyframes nc-icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes nc-icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
  
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .now-ui-icons.design_image:before {
    content: '\ea33';
  }
  
  .now-ui-icons.location_world:before {
    content: '\ea63';
  }
  
  .now-ui-icons.sport_user-run:before {
    content: '\ea60';
  }
  
  .navbar {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    min-height: 53px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  }
  
  .navbar a {
    vertical-align: middle;
  }
  
  .navbar a:not(.btn):not(.dropdown-item) {
    color: #ffffff;
  }
  
  .navbar p {
    display: inline-block;
    margin: 0;
    line-height: 21px;
    font-weight: inherit;
    font-size: inherit;
  }
  
  .navbar .navbar-nav .nav-link.btn {
    padding: 11px 22px;
  }
  
  .navbar .navbar-nav .nav-link.btn.btn-lg {
    padding: 15px 48px;
  }
  
  .navbar .navbar-nav .nav-link:not(.btn) {
    text-transform: uppercase;
    font-size: 0.7142em;
    padding: 0.5rem 0.7rem;
    line-height: 1.625rem;
  }
  
  .navbar .navbar-nav .nav-link:not(.btn) i.fab + p,
  .navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons + p {
    margin-left: 3px;
  }
  
  .navbar .navbar-nav .nav-link:not(.btn) i.fab,
  .navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
    font-size: 18px;
    position: relative;
    top: 3px;
    text-align: center;
    width: 21px;
  }
  
  .navbar .navbar-nav .nav-link:not(.btn) i.now-ui-icons {
    top: 4px;
    font-size: 16px;
  }
  
  .navbar .navbar-nav .nav-item.active .nav-link:not(.btn),
  .navbar .navbar-nav .nav-item .nav-link:not(.btn):focus,
  .navbar .navbar-nav .nav-item .nav-link:not(.btn):hover,
  .navbar .navbar-nav .nav-item .nav-link:not(.btn):active {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.1875rem;
  }
  
  .navbar .navbar-brand {
    text-transform: uppercase;
    font-size: 0.8571em;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.625rem;
  }
  
  .navbar .navbar-toggler {
    width: 37px;
    height: 27px;
    outline: 0;
    cursor: pointer;
  }
  
  .navbar .navbar-toggler .navbar-toggler-bar.middle-bar {
    width: 17px;
    transition: width 0.2s linear;
  }
  
  .navbar .navbar-toggler:hover .navbar-toggler-bar.middle-bar {
    width: 22px;
  }
  
  .navbar .button-dropdown .navbar-toggler-bar:nth-child(2) {
    width: 17px;
  }
  
  .navbar.navbar-transparent {
    background-color: transparent !important;
    box-shadow: none;
    color: #ffffff;
    padding-top: 20px !important;
  }
  
  .bg-primary {
    background-color: #f96332 !important;
  }
  
  .dropdown-menu {
    border: 0;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.125rem;
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
    font-size: 14px;
  }
  
  .dropdown .dropdown-menu {
    -webkit-transform: translate3d(0, -25px, 0) !important;
    visibility: hidden;
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
    top: 100% !important;
  }
  
  .dropdown-menu .dropdown-item {
    font-size: 0.8571em;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-top: 5px;
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
  }
  
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:focus {
    background-color: rgba(222, 222, 222, 0.3);
  }
  
  .dropdown-menu .dropdown-item:disabled {
    color: rgba(182, 182, 182, 0.6);
  }
  
  .dropdown-menu .dropdown-item:disabled:hover,
  .dropdown-menu .dropdown-item:disabled:focus {
    background-color: transparent;
  }
  
  .dropdown-menu:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: '';
    top: -5px;
    left: 10px;
    right: auto;
    color: #ffffff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }
  
  .dropdown-menu .dropdown-divider {
    background-color: rgba(222, 222, 222, 0.5);
  }
  
  .dropdown-menu .dropdown-header:not([href]):not([tabindex]) {
    color: rgba(182, 182, 182, 0.6);
    font-size: 0.7142em;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  .button-dropdown {
    padding-right: 0.7rem;
    cursor: pointer;
  }
  
  .button-dropdown .dropdown-toggle {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: block;
  }
  
  .button-dropdown .dropdown-toggle:after {
    display: none;
  }
  
  img {
    max-width: 100%;
    border-radius: 1px;
  }
  
  .img-raised {
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  }
  
  
  .profile-page .photo-container {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  }
  
  .profile-page .title {
    text-align: center;
    margin-top: 30px;
  }
  
  .profile-page .description,
  .profile-page .category {
    text-align: center;
  }
  
  .profile-page h5.description {
    max-width: 700px;
    margin: 20px auto 75px;
  }
  
  .profile-page .nav-align-center {
    margin-top: 30px;
  }
  
  .profile-page .content {
    max-width: 450px;
    margin: 0 auto;
  }
  
  .profile-page .content .social-description {
    display: inline-block;
    max-width: 150px;
    width: 145px;
    text-align: center;
    margin: 15px 0 0px;
    color: black !important;
    text-transform: uppercase;
  }
  
  .profile-page .content .social-description h5 {
    margin-bottom: 15px;
    color: black !important;
  }
  .profile-page .content .social-description p {
    color: black !important;
    font-weight: 500;
  }
  
  .profile-page .button-container {
    text-align: center;
    margin-top: -106px;
  }
  
  .profile-page .collections img {
    margin-bottom: 30px;
  }
  
  .profile-page .gallery {
    margin-top: 45px;
    padding-bottom: 50px;
  }
  

  
  .section {
    padding: 70px 0;
    position: relative;
    background: #ffffff;
  }
  
  .section .row + .category {
    margin-top: 15px;
  }
  
  .page-header {
    padding: 0;
    color: #ffffff;
    position: relative;
    overflow: hidden;
  }
  
  .page-header > .content {
    margin-top: 12%;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .page-header > .container {
    z-index: 2;
    padding-top: 42px;
    padding-bottom: 40px;
  }
  
  .page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .page-header .container {
    height: 100%;
    z-index: 1;
    text-align: center;
    position: relative;
  }
  
  .page-header .category,
  .page-header .description {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .page-header:after,
  .page-header:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: '';
  }
  
  .clear-filter:after,
  .clear-filter:before {
    display: none;
  }
  
  [data-background-color='orange'] {
    background-color: #e95e38;
  }
  
  [data-background-color='black'] {
    background-color: #2c2c2c;
  }
  
  [data-background-color]:not([data-background-color='gray']) {
    color: #ffffff;
  }
  
  [data-background-color]:not([data-background-color='gray']) .title,
  [data-background-color]:not([data-background-color='gray'])
    .social-description
    h2,
  [data-background-color]:not([data-background-color='gray']) p {
    color: #ffffff;
  }
  
  [data-background-color]:not([data-background-color='gray']) h2,
  [data-background-color]:not([data-background-color='gray']) h3,
  [data-background-color]:not([data-background-color='gray']) h4,
  [data-background-color]:not([data-background-color='gray']) h5,
  [data-background-color]:not([data-background-color='gray'])
    a:not(.btn):not(.dropdown-item) {
    color: #ffffff;
  }
  
  [data-background-color]:not([data-background-color='gray']) .description,
  [data-background-color]:not([data-background-color='gray'])
    .social-description
    p {
    color: rgba(255, 255, 255, 0.8);
  }
  
  @media screen and (max-width: 991px) {
    .button-cont{
      flex-wrap: wrap;
    }
    .mybtn3{
      width: 100% !important;
    }
    .mybtn4{
      margin-top: 24px;
      width: 100% !important;
    }
    .single-turnaments .right-area .r-bottom-area{
      flex-wrap: wrap
    }
    .single-turnaments .right-area .r-bottom-area .rr-area{
      width: 100%;
    }
    .single-turnaments .left-area{
      width: 100% !important;
    }
    .btn{
      font-size: 16px;
    }
    .sidebar-collapse .navbar-collapse {
      position: fixed;
      display: block;
      top: 0;
      height: 100% !important;
      width: 300px;
      right: 0;
      z-index: 1032;
      visibility: visible;
      background-color: #999;
      overflow-y: visible;
      border-top: none;
      text-align: left;
      max-height: none !important;
      -webkit-transform: translate3d(300px, 0, 0);
      -moz-transform: translate3d(300px, 0, 0);
      -o-transform: translate3d(300px, 0, 0);
      -ms-transform: translate3d(300px, 0, 0);
      transform: translate3d(300px, 0, 0);
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  
    .sidebar-collapse .navbar-collapse:before {
      background: #f96332;
      background: -webkit-linear-gradient(#f96332 0%, #000 80%);
      background: -o-linear-gradient(#f96332 0%, #000 80%);
      background: -moz-linear-gradient(#f96332 0%, #000 80%);
      background: linear-gradient(#f96332 0%, #000 80%);
      opacity: 0.76;
      filter: alpha(opacity=76);
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
  
    .sidebar-collapse .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link {
      margin: 0 1rem;
      margin-top: 0.3125rem;
    }
  
    .sidebar-collapse
      .navbar-collapse
      .navbar-nav:not(.navbar-logo)
      .nav-link:not(.btn) {
      color: #ffffff !important;
    }
  
    .sidebar-collapse .navbar-collapse .dropdown-menu .dropdown-item {
      color: #ffffff;
    }
  
    .sidebar-collapse .navbar .navbar-nav {
      margin-top: 53px;
      position: relative;
      max-height: calc(100vh - 75px);
      min-height: 100%;
      overflow: auto;
    }
  
    .sidebar-collapse .navbar .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0 1rem;
      margin-bottom: 15px;
      padding-top: 0;
      height: 125px;
      overflow-y: scroll;
    }
  
    .sidebar-collapse .navbar .dropdown .dropdown-menu:before {
      display: none;
    }
  
    .sidebar-collapse .navbar .dropdown .dropdown-item {
      padding-left: 2.5rem;
    }
  
    .sidebar-collapse .navbar .dropdown .dropdown-menu {
      display: none;
    }
  
    .sidebar-collapse .navbar .dropdown-menu .dropdown-item:focus,
    .sidebar-collapse .navbar .dropdown-menu .dropdown-item:hover {
      color: #ffffff;
      border-radius: 0.1875rem;
    }
  
    .sidebar-collapse .navbar .navbar-translate {
      width: 100%;
      position: relative;
      display: flex;
  
      justify-content: space-between !important;
      align-items: center;
      -webkit-transform: translate3d(0px, 0, 0);
      transform: translate3d(0px, 0, 0);
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  
    .sidebar-collapse .navbar .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: #ffffff;
    }
  
    .sidebar-collapse .navbar .navbar-toggler-bar + .navbar-toggler-bar {
      margin-top: 7px;
    }
  
    .sidebar-collapse .top-bar {
      transform: rotate(0);
    }
  
    .sidebar-collapse .middle-bar {
      opacity: 1;
    }
  
    .sidebar-collapse .bottom-bar {
      transform: rotate(0);
    }
  
    .sidebar-collapse .top-bar,
    .sidebar-collapse .middle-bar,
    .sidebar-collapse .bottom-bar {
      transition: all 0.2s;
    }
  
    .sidebar-collapse [class*='navbar-expand-'] .navbar-collapse {
      width: 300px;
    }
  
    .sidebar-collapse .wrapper {
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
  
    .navbar-nav .nav-link i.fab,
    .navbar-nav .nav-link i.now-ui-icons {
      opacity: 0.5;
    }
  
    .button-dropdown {
      display: none;
    }
  
    .navbar-nav .nav-link i.now-ui-icons {
      opacity: 0.5;
    }
    .profile-page .page-header:before {
      top: 10%;
    }
    .profile-page .non-connected:before {
      top: 10%;
    }
    .show-mobile {
      display: block;
    }
    .hide-mobile {
      display: none;
    }
    .mobile-width {
      width: 100%;
    }
    .non-connected{
      top: 10;
    }
    .mobile-margin {
      margin-top: 16px;
    }
    .profile-page .content .social-description {
      max-width: 100%;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 1210px) and (min-width: 992px) {
    .single-turnaments .left-area{
      width: 50% !important; 
    }
  }
  
  .loader-container {
    opacity: 0.7;
    z-index: 444444;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
  }
  .loader-element{
  
      left: 50%;
      position: absolute;
      bottom: 50%;
  
  }
  