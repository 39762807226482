@charset "UTF-8";
/* Typography */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;300;400;500;600;700&family=Open+Sans:wght@300;400;600;700;800&display=swap');
/* Normalize  */
html {
  font-family: 'Open Sans', sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  margin: 0;
  color: #a1aed4;
  overflow-x: hidden;
  background-color: #101010;
  background: #101010;
}

h1 {
  font-size: 60px;
  line-height: 1.0833333333;
}

h2 {
  font-size: 52px;
  line-height: 1.4444444444;
}

h3 {
  font-size: 26px;
  line-height: 1.0833333333;
}

h4 {
  font-size: 22px;
  line-height: 1.2380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
  font-family: 'Josefin Sans', sans-serif;
}

p {
  font-size: 18px;
  color: #a1aed4;
  line-height: 1.625;
  -webkit-hyphens: auto;
  hyphens: auto;
}

a {
  color: #fff;
  text-decoration: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}

/*input and button type focus outline disable*/
input[type='text']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='number']:focus,
textarea:focus,
input[type='button']:focus,
input[type='reset']:focus,
input[type='submit']:focus,
select:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

/**
 * 5.0 - Alignments
 */
.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

/* Global style */
.mybtn1 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: white;
  padding: 12px 35px;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px 7px #fea036;
  transition: all 0.3s ease-in;
  display: inline-block;
}
.mybtn1:hover {
  color: #fff;
  box-shadow: inset 0px 0px 10px 7px white;
}

.mybtn2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  color: white;
  padding: 12px 35px;
  border-radius: 50px;
  box-shadow: inset 0px 0px 10px 7px white;
  transition: all 0.3s ease-in;
  display: inline-block;
}
.mybtn2:hover,
.mybtn2.active {
  color: #fff;
  box-shadow: inset 0px 0px 10px 7px #fea036;
}

.mybtn3 {
  border: none;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  background: rgb(43, 182, 182);
  border-radius: 10px;
  width: 40%;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 35px;
  border-radius: 10px;
  /* box-shadow: inset 0px 0px 10px 7px white; */
  transition: all 0.3s ease-in;
  display: inline-block;
}

.mybtn3 h5 {
  margin-bottom: 0;
}
.mybtn3:hover,
.mybtn3.active {
  color: black;
  background: #499093;
  /* box-shadow: inset 0px 0px 10px 7px #c340e0; */
}
.mybtn4 {
  border: none;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  background: #de4172;
  border-radius: 10px;
  width: 40%;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 35px;
  border-radius: 10px;
  /* box-shadow: inset 0px 0px 10px 7px white; */
  transition: all 0.3s ease-in;
  display: inline-block;
}
.mybtn4:hover,
.mybtn4.active {
  color: black;
  background: #499093;
  /* box-shadow: inset 0px 0px 10px 7px #c340e0; */
}

.button-cont {
  margin-top: 0 !important;
  display: flex;
  justify-content: space-around;
  margin: 64px 0;
}

/* Section Heading Css */
.section-heading {
  text-align: center;
  margin-bottom: 54px;
}
.section-heading.content-left {
  text-align: left;
}
.section-heading .subtitle {
  font-size: 42px;
  line-height: 38px;
  font-weight: 700;
  color: #fa009f;
  margin-bottom: 13px;
}
.section-heading .title {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 7px;
  text-shadow: 0px -3px 10px rgba(12, 19, 55, 0.7);
}
.section-heading .text {
  font-size: 28px;
  line-height: 28px;
  font-weight: 400;
  color: #fff;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border: none !important;
  margin: 0px;
  padding: 0px;
}

.navbar-toggler:focus {
  outline: 0px;
}

/* Preloader Css */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0b122e;
  z-index: 9999999;
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.preloader.hide {
  opacity: 0;
  display: none;
}

.hero-area.hero-area2 .content {
  text-align: left;
}
.hero-area.hero-area2.hero-area3 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.hero-area.hero-area2.hero-area3 .content {
  text-align: center;
}
.hero-area.hero-area2.hero-area4 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.hero-area.hero-area2.hero-area4 .content {
  text-align: center;
}
.hero-area.hero-area2.hero-area5 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.hero-area.hero-area2.hero-area5 .content {
  text-align: center;
}

/* Hero Area End */
/* Counter Area Start */
.counter-section {
  padding: 40px 0px 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0px 15px 15px -10px white, inset 0px -15px 15px -10px white;
}
.counter-section.counter-section2 {
  padding-top: 68px;
  padding-bottom: 70px;
  overflow: hidden;
}
.counter-section .left-info {
  padding-top: 50px;
}
.counter-section .left-info h5 {
  font-size: 24px;
  line-height: 34px;
  color: #fa009f;
  font-weight: 600;
}
.counter-section .left-info h3 {
  font-size: 35px;
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
}
.counter-section .left-info .mybtn1 {
  margin-top: 27px;
  padding: 5px 30px;
  font-size: 20px;
  box-shadow: inset 0px 0px 6px 4px #fea036;
}
.counter-section .left-info .mybtn1:hover {
  color: #fff;
  box-shadow: inset 0px 0px 6px 4px white;
}
.counter-section .prize-pool {
  position: relative;
  z-index: 9;
  height: 100%;
  text-align: center;
}
.counter-section .prize-pool h6 {
  font-size: 24px;
  line-height: 34px;
  color: white;
  font-weight: 700;
}
.counter-section .prize-pool h3 {
  margin-top: 5px;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #261855;
  text-shadow: 0px 0px 18px rgba(164, 17, 220, 0.99), 2px 0px 0px #fe77ff,
    0px 2px 0px #fe77ff, -2px 0px 0px #fe77ff, 0px -2px 0px #fe77ff;
  font-family: 'Open Sans', sans-serif;
}
.counter-section .prize-pool .timecounter {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}
.counter-section .prize-pool .timecounter div {
  display: inline-block;
}
.counter-section .prize-pool img {
  position: absolute;
  bottom: -70px;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
.counter-section .l-winner-slider-wrapper {
  padding-top: 50px;
}
.counter-section .l-winner-slider-wrapper h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.counter-section .l-winner-slider-wrapper .l-winner-slider .s-item .img {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 4px 2px white;
  background: #270b59;
  padding: 14px;
}
.counter-section .l-winner-slider-wrapper .l-winner-slider .s-item .img img {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.counter-section .l-winner-slider-wrapper .l-winner-slider .s-item .img span {
  position: absolute;
  font-size: 16px;
  display: inline-block;
  box-shadow: inset 0px 0px 4px 3px #fea036;
  padding: 1px 10px;
  border-radius: 50px;
  color: #fff;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: #620782;
}
.counter-section .l-winner-slider-wrapper .l-winner-slider .owl-nav {
  display: block;
  margin-top: 35px;
}
.counter-section .l-winner-slider-wrapper .l-winner-slider .owl-nav div {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  box-shadow: inset 0px 0px 6px 4px #7f72b3;
  transition: all 0.3s ease-in;
}
.counter-section .l-winner-slider-wrapper .l-winner-slider .owl-nav div:hover {
  box-shadow: inset 0px 0px 6px 4px white;
}
.counter-section
  .l-winner-slider-wrapper
  .l-winner-slider
  .owl-nav
  div.owl-prev {
  margin-right: 10px;
}
.counter-box {
  display: flex;
  align-items: center;
  position: relative;
}
.counter-box .myborder {
  position: absolute;
  height: 90%;
  width: 1px;
  right: -14px;
  background: #504f8d;
}
.counter-box .image img {
  width: 144px;
  margin-right: 20px;
}
.counter-box .content {
  flex: 1;
}
.counter-box .content h4 {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  text-shadow: 0px 0px 20px white;
  color: white;
  margin-bottom: 0px;
}
.counter-box .content h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0px;
}

.couser-section2 {
  padding-top: 105px;
}
.couser-section2 .scounter2 {
  position: relative;
}
.couser-section2 .scounter2 .content {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.couser-section2 .scounter2 .content h4 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-shadow: 0px 0px 20px white;
  color: white;
  margin-bottom: 0px;
}
.couser-section2 .scounter2 .content h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 0px;
}

/* Counter Area End */
/* Ex-lottery Area Start */
.ex-lottery {
  padding: 120px 0px 85px;
  background: #101010;
}
.ex-lottery .ex-lottery-slider .owl-nav {
  display: block;
  margin-top: 35px;
}
.ex-lottery .ex-lottery-slider .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  box-shadow: inset 0px 0px 6px 4px #7f72b3;
  transition: all 0.3s ease-in;
}
.ex-lottery .ex-lottery-slider .owl-nav div:hover {
  box-shadow: inset 0px 0px 6px 4px white;
}
.ex-lottery .ex-lottery-slider .owl-nav div.owl-prev {
  margin-right: 10px;
  left: -20px;
}
.ex-lottery .ex-lottery-slider .owl-nav div.owl-next {
  right: -20px;
}
.ex-lottery.ex-lottery2 {
  padding-bottom: 0px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}
.ex-lottery.ex-lottery2 .ex-lottery-slider {
  margin-bottom: -257px;
}

.single-tikit {
  border-radius: 10px;
  box-shadow: inset 0px 0px 10px white;
  background: #330662;
  transition: all 0.3s ease-in;
}
.single-tikit .image {
  padding: 30px 30px 20px;
  position: relative;
  text-align: center;
}
.single-tikit .image .tag {
  position: absolute;
  top: 30px;
  left: 30px;
  background-image: linear-gradient(
    -103deg,
    #ef774c 0%,
    #ed684f 35%,
    #e84351 76%,
    #e73351 100%
  );
  box-shadow: 0px 10px 10px 0px rgba(34, 51, 79, 0.3);
  border-radius: 50px;
  text-align: center;
  padding: 3px 15px;
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
}
.single-tikit .image img {
  max-width: 100%;
  width: auto;
  height: 240px;
  display: inline-block;
}
.single-tikit .content .top-area .top-info {
  padding: 0px 30px;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
}
.single-tikit .content .top-area .top-info span {
  font-size: 14px;
  line-height: 24px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 600;
  color: #ea842a;
}
.single-tikit .content .top-area .top-info span i {
  margin-right: 5px;
}
.single-tikit .content .top-area .top-bar {
  position: relative;
}
.single-tikit .content .top-area .top-bar .progress-bar {
  position: absolute;
  height: 5px;
  width: 70%;
  background: #ea842a;
}
.single-tikit .content .top-area .top-bar .main-bar {
  background: #400a73;
  height: 5px;
  width: 100%;
}
.single-tikit .content .content-area {
  padding: 25px 30px 30px;
}
.single-tikit .content .content-area h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
}
.single-tikit .content .content-area .c-bottom {
  display: flex;
  justify-content: space-between;
}
.single-tikit .content .content-area .c-bottom span {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #827db8;
}
.single-tikit .content .content-area .c-bottom .mybtn1 {
  font-size: 14px;
  padding: 4px 20px;
  box-shadow: inset 0px 0px 5px 3px #fea036;
}
.single-tikit .content .content-area .c-bottom .mybtn1:hover {
  color: #fff;
  box-shadow: inset 0px 0px 5px 3px white;
}
.single-tikit:hover {
  box-shadow: inset 0px 0px 8px 3px #fea036;
}

/* Ex-lottery Area End */
/* Latest arcive Area Start */
.latest-arcive {
  background: #101010;
  padding: 120px 0px 120px;
}

.sh-wrpper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.sh-wrpper img {
  width: 100px;
  margin-right: 30px;
}
.sh-wrpper .section-heading {
  flex: 1;
  text-align: left;
  margin-bottom: 0px;
}
.sh-wrpper .section-heading .subtitle {
  font-size: 28px;
  line-height: 38px;
}
.sh-wrpper .section-heading .title {
  font-size: 50px;
  line-height: 60px;
}

.l-arcive-box {
  border-radius: 20px;
  background-color: #24074f;
  padding: 30px;
}
.l-arcive-box .s-a-b {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 4px solid #211863;
  border-bottom: 4px solid #211863;
  margin-bottom: 10px;
  padding: 20px 0px 20px;
}
.l-arcive-box .s-a-b:last-child {
  margin-bottom: 0px;
}
.l-arcive-box .s-a-b .left {
  display: flex;
  align-items: center;
}
.l-arcive-box .s-a-b .left img {
  margin-right: 20px;
  height: 70px;
}
.l-arcive-box .s-a-b .left h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #ced0e2;
  margin-bottom: 0px !important;
}
.l-arcive-box .s-a-b .right {
  display: flex;
  align-items: center;
}
.l-arcive-box .s-a-b .right img {
  margin-left: 20px;
}
.l-arcive-box .s-a-b .right h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #fcc310;
  margin-bottom: 0px;
}

.latest-arcive2 {
  background-color: #101010;
  padding: 120px 0px 56px;
}
.latest-arcive2 .nav {
  display: block;
  text-align: center;
}
.latest-arcive2 .nav li {
  display: inline-block;
}
.latest-arcive2 .nav li a {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  border-radius: 50px;
  margin: 0px 10px;
  padding: 9px 30px;
  box-shadow: inset 0px 0px 6px 4px #7f72b3, 0px 0px 16px #7f72b3;
  transition: all 0.3s ease-in;
}
.latest-arcive2 .nav li a:hover,
.latest-arcive2 .nav li a.active {
  box-shadow: inset 0px 0px 6px 4px white, 0px 0px 16px white;
}
.latest-arcive2 .l-a-nav {
  margin-top: 40px;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: inset 0px 0px 6px 2px white, 0px 0px 10px white;
  padding: 50px 30px;
  background: #220c44;
}
.latest-arcive2 .l-a-nav .left-content .heading-area {
  margin-bottom: 45px;
}
.latest-arcive2 .l-a-nav .left-content .heading-area h6 {
  color: #fa009f;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin-top: 25px;
}
.latest-arcive2 .l-a-nav .left-content .heading-area h3 {
  font-size: 35px;
  line-height: 43px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-top: 20px;
}
.latest-arcive2 .l-a-nav .left-content ul li {
  margin-bottom: 20px;
}
.latest-arcive2 .l-a-nav .left-content ul li:last-child {
  margin-bottom: 0px;
}
.latest-arcive2 .l-a-nav .left-content ul li .info-box {
  display: flex;
  align-items: center;
}
.latest-arcive2 .l-a-nav .left-content ul li .info-box img {
  width: 90px;
  margin-right: 30px;
}
.latest-arcive2 .l-a-nav .left-content ul li .info-box .content {
  flex: 1;
}
.latest-arcive2 .l-a-nav .left-content ul li .info-box .content h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #fff;
}
.latest-arcive2 .l-a-nav .left-content ul li .info-box .content p {
  font-size: 16px;
  line-height: 26px;
  color: #b0b0ef;
}

.l-arcive-box2 .s-a-b {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 4px solid #211863;
  border-bottom: 4px solid #211863;
  margin-bottom: 10px;
  padding: 20px 20px 20px;
  background: #280a4b;
}
.l-arcive-box2 .s-a-b:last-child {
  margin-bottom: 0px;
}
.l-arcive-box2 .s-a-b .left {
  display: flex;
  align-items: center;
}
.l-arcive-box2 .s-a-b .left img {
  height: 70px;
}
.l-arcive-box2 .s-a-b .left .content {
  flex: 1;
  padding-left: 40px;
  display: flex;
  align-items: center;
}
.l-arcive-box2 .s-a-b .left .content .left2 img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 4px 2px white;
  background: #270b59;
  padding: 10px;
  margin-right: 20px;
}
.l-arcive-box2 .s-a-b .left .content .right2 {
  flex: 1;
}
.l-arcive-box2 .s-a-b .left .content .right2 h4 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #ced0e2;
  margin-bottom: 0px;
}
.l-arcive-box2 .s-a-b .left .content .right2 .stars {
  font-size: 10px;
  color: #f6cb41;
}
.l-arcive-box2 .s-a-b .right {
  text-align: center;
}
.l-arcive-box2 .s-a-b .right img {
  margin-bottom: 10px;
}
.l-arcive-box2 .s-a-b .right h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: #fcc310;
  margin-bottom: 0px;
}

/* Latest arcive Area End */
/* join us area Start */
.join_us {
  padding: 120px 0px 120px;
  background-color: #101010;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.join_us .l-image {
  position: absolute;
  bottom: 0px;
  left: 100px;
}
.join_us .section-heading {
  margin-bottom: 0px;
}
.join_us .section-heading .mybtn1 {
  margin-top: 30px;
  display: inline-block;
}
.join_us.join_us2 {
  background-image: none;
  padding-top: 377px;
  position: relative;
}
.join_us.join_us2 .r-image {
  position: absolute;
  right: 10%;
  top: 70%;
  transform: translateY(-50%);
}
.join_us.join_us2.join_us3 {
  padding-top: 120px;
}

.single-play {
  border-radius: 10px;
  box-shadow: inset 0px 0px 12px 7px white;
  text-align: center;
  padding: 40px 20px;
  transition: all 0.3s ease-in;
}
.single-play .image img {
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.single-play .contant {
  padding-top: 30px;
}
.single-play .contant a {
  display: inline-block;
}
.single-play .contant h4 {
  display: block;
  margin-top: 30px;
  color: #cfcffd;
  font-size: 16px;
  line-height: 26px;
}
.single-play .contant ul li {
  margin-left: -15px;
  display: inline-block;
}
.single-play .contant ul li:first-child {
  margin-left: 0px;
}
.single-play .contant ul li img {
  width: 30px;
  height: 30px;
  border: 1px solid #364374;
  border-radius: 50%;
}
.single-play .contant ul li span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  box-shadow: inset 0px 0px 7px 3px white;
  font-size: 10px;
  background: #300983;
}
.single-play:hover {
  transform: translateY(-5px);
  box-shadow: inset 0px 0px 12px 7px #fea036;
}

/* Game play Area End */
/* Recent Winner Area Start */
.recent-winners {
  background: #101010;
  padding: 377px 0px 100px;
}
.recent-winners .top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}
.recent-winners .top-section h3 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.recent-winners .top-section a {
  font-size: 18px;
  line-height: 28px;
  color: #fa009f;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.single-winner {
  box-shadow: inset 0px 0px 10px 5px white, 0px 0px 10px white;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.single-winner .img {
  width: 125px;
  height: 100%;
  text-align: center;
}
.single-winner .img img {
  display: inline-block;
}
.single-winner .content {
  flex: 1;
  padding: 30px 20px 30px 10px;
}
.single-winner .content .top-content {
  display: flex;
  align-items: center;
}
.single-winner .content .top-content img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 4px 2px white;
  background: #270b59;
  padding: 10px;
  margin-right: 20px;
}
.single-winner .content .top-content .lc {
  flex: 1;
}
.single-winner .content .top-content .lc h6 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  color: #ced0e2;
  margin-bottom: -7px;
}
.single-winner .content .top-content .lc span {
  font-size: 10px;
  line-height: 16px;
  color: #f6cb41;
}
.single-winner .content .numbers {
  margin-top: 15px;
}
.single-winner .content .numbers span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  font-size: 10px;
  box-shadow: inset 0px 0px 5px 3px #fea036;
  font-weight: 600;
  margin-right: 5px;
  color: #fff;
}
.single-winner .content .numbers span:last-child {
  margin-right: 0px;
}
.single-winner .content .numbers span:nth-child(2) {
  box-shadow: inset 0px 0px 5px 3px #ff6600;
}
.single-winner .content .numbers span:nth-child(3) {
  box-shadow: inset 0px 0px 5px 3px white;
}
.single-winner .content .numbers span:nth-child(4) {
  box-shadow: inset 0px 0px 5px 3px #eeff00;
}
.single-winner .content .numbers span:nth-child(5) {
  box-shadow: inset 0px 0px 5px 3px #ff009d;
}

/* Recent Winner Area End */
/*	Index 2 Css Start */
.index2 .header .mainmenu-area {
  background: none;
}
.index2 .header .mainmenu-area .navbar::before,
.index2 .header .mainmenu-area .navbar::after {
  display: none;
}
.index2 .header .mainmenu-area .navbar .navbar-brand {
  padding-left: 0px;
}
.index2
  .header
  .mainmenu-area
  .navbar
  #main_menu
  .navbar-nav
  .nav-item
  .nav-link
  .mr-hover-effect {
  display: none;
}
.index2 .hero-area {
  padding: 372px 0px 424px;
}
.index2 .hero-area .left-content .content {
  text-align: center;
}
.index2 .features {
  background: #070b28;
  padding: 277px 0px 90px;
}
.index2 .features .section-heading {
  margin-bottom: 58px;
}
.index2 .features .feature-box .feature-box-inner {
  position: unset;
  width: 100%;
  top: auto;
}
.index2 .featured-game {
  padding: 110px 0px 120px;
}

.single-feature.landscape {
  display: flex;
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 45px 30px 45px 45px;
  background: none;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
  margin-bottom: 30px;
}
.single-feature.landscape .icon {
  margin-right: 45px;
  margin-bottom: 0px;
  background: #242e5a;
}
.single-feature.landscape .content {
  flex: 1;
  text-align: left;
  align-self: center;
}

/*	Index 2 Css End */
/* About Area Start */
/*	About Area  End */
/* About page section Area Start */
.about-section {
  background: #0b122e;
  padding: 112px 0px 120px;
  position: relative;
}
.about-section .ar {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
  max-width: 600px;
}
.about-section .s1 {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  height: 70%;
}
.about-section .s2 {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  height: 70%;
}
.about-section .left-content {
  text-align: left;
}
.about-section .left-content .section-heading {
  text-align: left;
}
.about-section .left-content .section-heading .text {
  color: #dbdcf7;
}
.about-section .left-content .content-list {
  display: block;
}
.about-section .left-content .content-list .s-list {
  display: flex;
  align-items: center;
  float: left;
  margin-right: 50px;
}
.about-section .left-content .content-list .s-list img {
  margin-right: 30px;
}
.about-section .left-content .content-list .s-list .content {
  flex: 1;
  font-size: 18px;
  line-height: 28px;
}
.about-section .left-content .content-list .s-list .content p {
  margin-bottom: 0px;
}
.about-section .left-content .content-list .s-list:last-child {
  margin-right: 0px;
}

/* About page section Area End */
/* Features Area Start */
.features-section {
  padding: 120px 0px 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.features-section .s1 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  max-height: 750px;
}
.features-section .s2 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-height: 750px;
}

.single-feature {
  box-shadow: inset 0px 0px 10px 5px white, 0px 0px 10px white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
}
.single-feature .img {
  position: relative;
  width: 100%;
  height: 120px;
}
.single-feature .img .shape {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.single-feature .img .icon {
  height: 100%;
  width: auto;
  position: relative;
  z-index: 2;
}
.single-feature .content {
  padding-top: 30px;
}
.single-feature .content h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-transform: uppercase;
  color: #dbdcf7;
}
.single-feature .content p {
  font-size: 18px;
  line-height: 28px;
  color: #dbdcf7;
  margin-bottom: 0px;
}

/* Features Area End */
/* Counter Area Start */
.cuners3 {
  background: #101010;
  padding: 120px 0px 120px;
}

/* Counter Area End */
/* Team Area Start */
.team-section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 120px 0px 120px;
}
.team-section .section-heading {
  margin-bottom: 90px;
}
.team-section .single-team .image {
  position: relative;
}
.team-section .single-team .image .shape {
  width: 100%;
}
.team-section .single-team .image .member {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
}
.team-section .single-team .image .link {
  position: absolute;
  bottom: 65px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  right: 22%;
  border-radius: 50%;
  background-image: linear-gradient(0deg, #e2906e 0%, #e83a99 100%);
}
.team-section .single-team .image h4 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #fff;
  bottom: 7px;
}

/* Team Area End */
/* join us area Start */
.join_us_new {
  padding: 40px 0px 0px;
  background-color: #101010;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.join_us_new .section-heading {
  margin-bottom: 0px;
}
.join_us_new .section-heading .mybtn1 {
  margin-top: 30px;
  display: inline-block;
}

/* join us area end */
/* Games Filter Start */
.games-filter {
  padding: 40px 0px 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0px 15px 15px -10px white, inset 0px -15px 15px -10px white;
}
.games-filter .filter-wrapp {
  display: flex;
}
.games-filter .filter-wrapp .left-area .mybtn2 {
  font-size: 16px;
  text-transform: capitalize;
  padding: 14px 20px;
  color: white;
  margin-right: 6px;
}
.games-filter .filter-wrapp .left-area .mybtn2:last-child {
  margin-right: 0px;
}
.games-filter .filter-wrapp .left-area .mybtn2 i {
  margin-right: 5px;
  font-size: 20px;
  position: relative;
  top: 2px;
}
.games-filter .filter-wrapp .right-area {
  flex: 1;
  text-align: right;
}
.games-filter .filter-wrapp .right-area form {
  position: relative;
}
.games-filter .filter-wrapp .right-area form input {
  box-shadow: inset 0px 0px 7px 4px white;
  background: none;
  width: 350px;
  height: 50px;
  border-radius: 50px;
  border: 0px;
  padding: 0px 30px;
  color: #837ad9;
}
.games-filter .filter-wrapp .right-area form input::placeholder {
  color: #837ad9;
}
.games-filter .filter-wrapp .right-area form button {
  top: 0px;
  right: 0px;
  position: absolute;
  width: 50px;
  height: 50px;
  background: none;
  border: 0px;
  color: white;
}
.games-filter .filter-wrapp .right-area form button:focus {
  outline: none;
}

.game-play-section.game-play-section2 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.game-play-section.game-play-section2 .single-play {
  margin-bottom: 30px;
}

/* Games Filter End */
/* Daily Contest Start */
.daily-contest {
  background: #25094f;
  padding: 100px 0px 100px;
}
.daily-contest .left-content {
  display: flex;
  align-items: center;
}
.daily-contest .left-content .images img {
  max-width: 150px;
  margin-right: 20px;
}
.daily-contest .left-content .content {
  flex: 1;
}
.daily-contest .left-content .content h4 {
  color: #c8c3ff;
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 25px;
}
.daily-contest .left-content .content .coin {
  color: #fecc3a;
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;
  background: #2e0a63;
  padding: 10px 30px;
  display: inline-block;
  margin-bottom: 0px;
}
.daily-contest .left-content .content .coin span {
  color: #fff;
}
.daily-contest .middle-content {
  text-align: center;
}
.daily-contest .middle-content p {
  font-size: 16px;
  margin-bottom: 25px;
  display: block;
}
.daily-contest .middle-content .mybtn2 {
  display: inline-block;
}
.daily-contest .right-content {
  background: #2e0a63;
  padding: 40px 30px 40px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.daily-contest .right-content .winner {
  position: absolute;
  left: 0px;
  top: 0px;
}
.daily-contest .right-content .title {
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #c8c3ff;
  margin-bottom: 38px;
  display: block;
  padding-left: 15px;
}
.daily-contest .right-content .title i {
  margin-right: 5px;
}
.daily-contest .right-content .rc-bottom {
  display: flex;
  align-items: center;
}
.daily-contest .right-content .rc-bottom .img {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 4px 1px #daae37;
  border: 1px solid #daae37;
  background: #270b59;
  padding: 7px;
  margin-right: 20px;
}
.daily-contest .right-content .rc-bottom .img img {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.daily-contest .right-content .rc-bottom .img .crown {
  position: absolute;
  top: -16px;
  height: 19px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}
.daily-contest .right-content .rc-bottom .content {
  flex: 1;
}
.daily-contest .right-content .rc-bottom .content h6 {
  font-size: 18px;
  line-height: 28px;
  color: #ced0e2;
  font-weight: 600;
  margin-bottom: 0px;
}
.daily-contest .right-content .rc-bottom .content p {
  margin-bottom: 0px;
  font-size: 14px;
  color: #fecc3a;
  font-weight: 600;
}
.daily-contest .right-content .rc-bottom .content p span {
  color: #fff;
}

/* Daily Contest End */
.breadcrumb-area.games {
  position: relative;
}
.breadcrumb-area.games .content {
  position: absolute;
  bottom: 0px;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  text-align: center;
}
.breadcrumb-area.games .content h4 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 25px;
}

/* Games Details Top Bar Start */
.games-details-top-bar {
  padding: 20px 0px 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0px 15px 15px -10px white, inset 0px -15px 15px -10px white;
}
.games-details-top-bar .games-d-t-b-i {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.games-details-top-bar .games-d-t-b-i .left-area {
  display: flex;
  align-items: center;
}
.games-details-top-bar .games-d-t-b-i .left-area .modal-link {
  display: inline-block;
  text-align: center;
  margin-right: 30px;
}
.games-details-top-bar .games-d-t-b-i .left-area .modal-link:last-child {
  margin-right: 0px;
}
.games-details-top-bar .games-d-t-b-i .left-area .modal-link i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #273b71;
  border-radius: 10px;
  background-color: rgba(76, 7, 104, 0);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  color: white;
}
.games-details-top-bar .games-d-t-b-i .left-area .modal-link span {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  color: #dadeff;
  font-weight: 600;
  margin-top: 10px;
}
.games-details-top-bar .games-d-t-b-i .right-area {
  display: flex;
  align-items: center;
}
.games-details-top-bar .games-d-t-b-i .right-area .player-wrapper {
  display: inline-block;
  text-align: right;
  margin-right: 30px;
}
.games-details-top-bar .games-d-t-b-i .right-area .player-wrapper span {
  font-size: 14px;
  text-transform: uppercase;
  color: #cfcffd;
  font-weight: 600;
}
.games-details-top-bar .games-d-t-b-i .right-area .player-wrapper h6 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0px;
}
.games-details-top-bar .games-d-t-b-i .right-area ul {
  display: inline-block;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.games-details-top-bar .games-d-t-b-i .right-area ul li {
  margin-left: -15px;
  display: inline-block;
}
.games-details-top-bar .games-d-t-b-i .right-area ul li:first-child {
  margin-left: 0px;
}
.games-details-top-bar .games-d-t-b-i .right-area ul li img {
  width: 50px;
  height: 50px;
  border: 1px solid #364374;
  border-radius: 50%;
}
.games-details-top-bar .games-d-t-b-i .right-area ul li span {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  box-shadow: inset 0px 0px 7px 3px white;
  font-size: 18px;
  background: #300983;
}

/* Games Details Top Bar End */
/* Games Details Start */
.games-details {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 120px 0px 120px;
}
.games-details .details-wrapper {
  box-shadow: inset 0px 0px 10px 5px white, 0px 0px 10px white;
  padding: 60px 60px 60px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
}
.games-details .details-wrapper .left-content {
  flex: 1;
  display: flex;
}
.games-details .details-wrapper .left-content .ll-content {
  text-align: center;
  width: 210px;
  margin-right: 30px;
}
.games-details .details-wrapper .left-content .ll-content .img {
  display: block;
}
.games-details .details-wrapper .left-content .ll-content .img img {
  display: inline-block;
  max-width: 100%;
}
.games-details .details-wrapper .left-content .ll-content .mybtn2 {
  margin-top: 50px;
}
.games-details .details-wrapper .left-content .lr-content {
  flex: 1;
}
.games-details .details-wrapper .left-content .lr-content h3 {
  font-size: 54px;
  line-height: 54px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.games-details .details-wrapper .left-content .lr-content h6 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #d6e2f5;
  margin-bottom: 50px;
}
.games-details .details-wrapper .left-content .lr-content h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.games-details .details-wrapper .left-content .lr-content p {
  color: #d6e2f5;
  font-size: 18px;
  line-height: 28px;
}
.games-details .details-wrapper .right-content .price-box {
  width: 385px;
  margin-left: 30px;
  box-shadow: inset 0px 0px 10px 5px white, 0px 0px 10px white;
  padding: 60px 30px 60px;
  border-radius: 20px;
  text-align: center;
}
.games-details .details-wrapper .right-content .price-box h6 {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}
.games-details .details-wrapper .right-content .price-box .price-qty {
  display: block;
  margin-bottom: 25px;
}
.games-details .details-wrapper .right-content .price-box .price-qty .q {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  color: #fa009f;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: inline-block;
}
.games-details .details-wrapper .right-content .price-box .price-qty .price {
  font-size: 50px;
  line-height: 50px;
  font-weight: 300;
  color: white;
  position: relative;
  padding: 0px 10px;
  top: 8px;
  text-shadow: 0px 0px 10px white, 0px 0px 10px white;
}
.games-details .details-wrapper .right-content .price-box .players {
  box-shadow: inset 0px 0px 10px 4px white, inset 0px 0px 10px 4px white;
  background-color: #220b82;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  font-weight: 300;
  font-size: 54px;
  color: #fa009f;
  display: inline-block;
  margin-top: 7px;
  margin-bottom: 30px;
}
.games-details .details-wrapper .right-content .price-box .price-pool-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 20px;
  padding-bottom: 16px;
  margin-bottom: 30px;
}
.games-details
  .details-wrapper
  .right-content
  .price-box
  .price-pool-area
  .left {
  color: #fff;
  font-size: 18px;
}
.games-details
  .details-wrapper
  .right-content
  .price-box
  .price-pool-area
  .right {
  display: flex;
}
.games-details
  .details-wrapper
  .right-content
  .price-box
  .price-pool-area
  .right
  .numb {
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  color: white;
  text-shadow: 0px 0px 10px white, 0px 0px 10px white;
}

/* Games Details End */
.modal .modal-content {
  border-radius: 30px;
  background-color: #250752;
  box-shadow: inset 0px 0px 12px 8px white;
  padding: 30px;
}
.modal .modal-content .modal-header {
  padding: 0px;
  border-bottom: 0px;
  padding-bottom: 20px;
}
.modal .modal-content .modal-header .modal-title {
  padding: 0px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}
.modal .modal-content .modal-header .modal-title i {
  color: white;
  margin-right: 10px;
  text-shadow: 0px 0px 10px white;
}
.modal .modal-content .modal-header .close {
  width: 30px;
  height: 30px;
  line-height: 25px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: #ddd7e4;
  font-size: 12px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: inset 0px 0px 10px white;
  background: #260753;
  opacity: 1;
  margin: 0px;
  padding: 0px;
  float: none;
  font-weight: normal;
  text-shadow: none;
}
.modal .modal-content .modal-header .close:focus {
  outline: 0px;
}
.modal .modal-content .modal-body {
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 30px;
  overflow: hidden;
  background-color: black;
}

/* Payment-method Area End */
/* Tournaments Area Start */
.turnament-top-slider {
  padding: 40px 0px 40px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0px 15px 15px -10px white, inset 0px -15px 15px -10px white;
}
.turnament-top-slider .l-winner-slider-wrapper h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 25px;
}
.turnament-top-slider .l-winner-slider-wrapper .l-winner-slider2 .s-item .img {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: inset 0px 0px 4px 2px white;
  background: #270b59;
  padding: 14px;
}
.turnament-top-slider
  .l-winner-slider-wrapper
  .l-winner-slider2
  .s-item
  .img
  img {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.turnament-top-slider
  .l-winner-slider-wrapper
  .l-winner-slider2
  .s-item
  .img
  span {
  position: absolute;
  font-size: 16px;
  display: inline-block;
  box-shadow: inset 0px 0px 4px 3px #fea036;
  padding: 1px 10px;
  border-radius: 50px;
  color: #fff;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: #620782;
}
.turnament-top-slider .l-winner-slider-wrapper .l-winner-slider2 .owl-nav {
  display: block;
}
.turnament-top-slider .l-winner-slider-wrapper .l-winner-slider2 .owl-nav div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  box-shadow: inset 0px 0px 6px 4px #7f72b3;
  transition: all 0.3s ease-in;
}
.turnament-top-slider
  .l-winner-slider-wrapper
  .l-winner-slider2
  .owl-nav
  div:hover {
  box-shadow: inset 0px 0px 6px 4px white;
}
.turnament-top-slider
  .l-winner-slider-wrapper
  .l-winner-slider2
  .owl-nav
  div.owl-prev {
  left: -20px;
}
.turnament-top-slider
  .l-winner-slider-wrapper
  .l-winner-slider2
  .owl-nav
  div.owl-next {
  right: -20px;
}

.turnaments-tab-section {
  background: #1a0c02;
}
.turnaments-tab-section .s-top-area {
  margin-bottom: 110px;
  display: block;
  padding: 20px 0px 20px;
  box-shadow: 0px 1px 5px white;
}
.turnaments-tab-section .s-top-area .top-left-title h4 {
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.turnaments-tab-section .s-top-area .t-t-s-nav ul {
  text-align: right;
  display: block;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.turnaments-tab-section .s-top-area .t-t-s-nav ul li {
  display: inline-block;
  margin-right: 20px;
}
.turnaments-tab-section .s-top-area .t-t-s-nav ul li:last-child {
  margin-right: 0px;
}
.turnaments-tab-section .s-top-area .t-t-s-nav ul li a {
  font-size: 16px;
  text-transform: capitalize;
}

.single-turnaments {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: black;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 110px;
}
.single-turnaments .left-area {
  padding: 64px 60px;
  width: 40%;
}
.single-turnaments .left-area .single-play {
  border-radius: 10px;
  box-shadow: inset 0px 0px 12px 7px white;
  text-align: center;
  background-color: black;
  /* width: 255px; */
}
.single-turnaments .left-area .single-play .image img {
  display: inline-block;
  border-radius: 50%;
  width: 80%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.single-turnaments .left-area .single-play .contant {
  padding-top: 15px;
}
.single-turnaments .left-area .single-play .contant a {
  display: inline-block;
}
.single-turnaments .left-area h4 {
  display: block;
  margin-top: 30px;
  color: #cfcffd;
  font-size: 16px;
  line-height: 26px;
}
.single-turnaments .left-area ul li {
  margin-left: -15px;
  display: inline-block;
}
.single-turnaments .left-area ul li:first-child {
  margin-left: 0px;
}
.single-turnaments .left-area ul li img {
  width: 30px;
  height: 30px;
  border: 1px solid #364374;
  border-radius: 50%;
}
.single-turnaments .left-area ul li span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  box-shadow: inset 0px 0px 7px 3px white;
  font-size: 10px;
  background: #300983;
}
.single-turnaments .right-area {
  padding-top: 0 !important;
  flex: 1;
  padding: 40px 20px 0px;
}
.single-turnaments .right-area .r-top-area {
  border-bottom: 1px solid #282b6a;
  padding-bottom: 15px;
}
.single-turnaments .right-area .r-top-area h4 {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #fff;
}

.single-turnaments .right-area .r-top-area .list p {
  font-size: 16px;
  line-height: 26px;
  color: #ada6e7;
  display: inline-block;
  margin-bottom: 0px;
}
.single-turnaments .right-area .r-top-area .list span {
  width: 1px;
  height: 20px;
  background: #6360c0;
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0px 15px;
}
.single-turnaments .right-area .r-bottom-area {
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
}
.single-turnaments .right-area .r-bottom-area .rl-area {
  text-align: center;
}
.single-turnaments .right-area .r-bottom-area .rl-area .title {
  font-weight: 400;
  font-size: 14px;
  color: #068be4;
  text-shadow: 0px 0px 75px #068be4, 0px 0px 7px #068be4, 0px 0px 7px #068be4;
  margin-bottom: 10px;
  display: inline-block;
}
.single-turnaments .right-area .r-bottom-area .rl-area .timecounter {
  display: flex;
  font-size: 24px;
  color: #fff;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.single-turnaments .right-area .r-bottom-area .rl-area .timecounter i {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 5px;
}
.single-turnaments .right-area .r-bottom-area .rl-area .timecounter div {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
}
.single-turnaments .right-area .r-bottom-area .rl-area img {
  margin-top: -50px;
}
.single-turnaments .right-area .r-bottom-area .rr-area {
  text-align: center;
}
.single-turnaments .right-area .r-bottom-area .rr-area h5 {
  color: #fcfcfc;
  font-size: 24px;
  line-height: 34px;
  text-transform: uppercase;
  text-shadow: 0px 0px 75px #068be4, 0px 0px 7px #068be4;
}
.apy-details {
  color: white;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0px 0px 75px #068be4, 0px 0px 7px #068be4;
}
.single-turnaments .right-area .r-bottom-area .rr-area h3 {
  margin-top: 5px;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #261855;
  text-shadow: 0px 0px 18px rgba(164, 17, 220, 0.99), 2px 0px 0px #fe77ff,
    0px 2px 0px #fe77ff, -2px 0px 0px #fe77ff, 0px -2px 0px #fe77ff;
  font-family: 'Open Sans', sans-serif;
}
.single-turnaments .right-area .r-bottom-area .rr-area .time-area {
  position: relative;
}
.single-turnaments .right-area .r-bottom-area .rr-area .time-area h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
/* Tournaments Area End */
/* Contact Section Start */
.contact_section {
  padding: 120px 0px 0px;
  background-color: #101010;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.contact_section .section-heading {
  margin-bottom: 0px;
}
.contact_section .mybtn1 {
  margin-top: 25px;
}

/* Contact Section End */
/* Turnaments Area Start */
.turnaments-section {
  background-color: #101010;
  padding-bottom: 120px;
}
.turnaments-section .turnaments-top-section {
  padding: 180px 0px 0px;
  background-color: #101010;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.turnaments-section .turnaments-top-section .single-turnaments {
  margin-bottom: 0px;
}
.turnaments-section
  .turnaments-top-section
  .single-turnaments
  .right-area
  .r-bottom-area2
  ul {
  margin-top: 50px;
}
.turnaments-section
  .turnaments-top-section
  .single-turnaments
  .right-area
  .r-bottom-area2
  ul
  li {
  display: inline-block;
  margin-right: 100px;
  position: relative;
  text-align: center;
}
.turnaments-section
  .turnaments-top-section
  .single-turnaments
  .right-area
  .r-bottom-area2
  ul
  li:last-child {
  margin-right: 0px;
}
.turnaments-section
  .turnaments-top-section
  .single-turnaments
  .right-area
  .r-bottom-area2
  ul
  li::before {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  background: #6360c0;
  right: -50px;
}
.turnaments-section
  .turnaments-top-section
  .single-turnaments
  .right-area
  .r-bottom-area2
  ul
  li
  span {
  font-size: 14px;
  line-height: 24px;
  color: #cfcffd;
  text-transform: uppercase;
}
.turnaments-section
  .turnaments-top-section
  .single-turnaments
  .right-area
  .r-bottom-area2
  ul
  li
  h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #fff;
  margin-top: 7px;
  margin-bottom: 0px;
}
.turnaments-section .turnaments-top-section .stf {
  padding: 20px 30px;
  background: #39156d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.turnaments-section .turnaments-top-section .stf .left h4 {
  display: block;
  color: #cfcffd;
  font-size: 16px;
  line-height: 26px;
}
.turnaments-section .turnaments-top-section .stf .left ul li {
  margin-right: 5px;
  display: inline-block;
}
.turnaments-section .turnaments-top-section .stf .left ul li:last-child {
  margin-right: 0px;
}
.turnaments-section .turnaments-top-section .stf .left ul li img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.turnaments-section .turnaments-top-section .stf .center .time-area {
  position: relative;
  text-align: center;
}
.turnaments-section .turnaments-top-section .stf .center .time-area h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.turnaments-section .turnaments-info {
  padding-top: 50px;
}
.turnaments-section .turnaments-info h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 30px;
}
.turnaments-section .turnaments-info .single-prize {
  position: relative;
}
.turnaments-section .turnaments-info .single-prize h6 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}
.turnaments-section .turnaments-info .single-prize ul li {
  font-size: 18px;
  line-height: 28px;
  color: #d1ccff;
}
.turnaments-section .turnaments-info .single-prize p {
  font-size: 18px;
  line-height: 28px;
  color: #d1ccff;
}

.breadcrumb-area.turnaments2 {
  position: relative;
}
.breadcrumb-area.turnaments2 .content {
  position: absolute;
  bottom: 0px;
  left: 50%;
  bottom: 150px;
  transform: translateX(-50%);
  text-align: center;
}
.breadcrumb-area.turnaments2 .content .title {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  text-shadow: 0px 0px 75px #068be4, 0px 0px 7px #068be4;
  margin-bottom: 10px;
  display: inline-block;
}
.breadcrumb-area.turnaments2 .content .timecounter {
  display: flex;
  font-size: 24px;
  color: #fff;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.breadcrumb-area.turnaments2 .content .timecounter i {
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 5px;
}
.breadcrumb-area.turnaments2 .content .timecounter div {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
}

/* Turnaments Area End */
/* Bonuse Section  Start */
.bonuse-section {
  background-repeat: repeat;
  padding-bottom: 90px;
}
.bonuse-section .header-area {
  background-color: #101010;
  padding-top: 76px;
  margin-top: -280px;
  border-radius: 6px;
  padding-bottom: 70px;
  background-repeat: repeat;
}
.bonuse-section .header-area h1 {
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  color: #fff;
}
.bonuse-section .header-area .mybtn2 {
  font-size: 20px;
  text-transform: capitalize;
  margin-top: 25px;
}

.single-bonus {
  border: 1px solid #483669;
  border-radius: 10px;
  padding: 20px 30px 30px;
  text-align: center;
  margin-bottom: 30px;
}
.single-bonus img {
  height: 150px;
}
.single-bonus h4 {
  font-size: 18px;
  line-height: 28px;
  color: #cdd0f0;
  font-weight: 400;
}
.single-bonus a {
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
  color: #cdd0f0;
  display: inline-block;
  border: 1px solid #483669;
  border-radius: 50px;
  padding: 3px 25px;
}
.single-bonus a i {
  font-size: 14px;
  margin-right: 3px;
}

/* Bonuse Section  End */
/* How to Get Bonuse Section  Start */
.how-get-bonuse-section {
  padding: 110px 0px 90px;
}
.how-get-bonuse-section .section-heading {
  text-align: center;
}
.how-get-bonuse-section .section-heading .title {
  font-size: 60px;
  line-height: 70px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
}
.how-get-bonuse-section .section-heading .text {
  font-size: 18px;
  line-height: 28px;
  color: #cdd0f0;
}

.h-bonus {
  text-align: center;
  margin-bottom: 30px;
}
.h-bonus img {
  height: 120px;
}
.h-bonus h6 {
  margin-top: 10px;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.h-bonus h6 a {
  display: inline-block;
  color: white;
}

/* How to Get Bonuse Section  End */
/* Awards Area Start */
.awards-area {
  padding: 112px 0px 90px;
  background: #070b28;
}
.awards-area .section-heading {
  margin-bottom: 58px;
}

.single-awards {
  margin-bottom: 55px;
  position: relative;
  display: block;
  text-align: center;
  border: 1px solid #273b71;
  border-radius: 10px;
  padding: 60px 40px 84px;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in;
}
.single-awards .content img {
  display: inline-block;
  margin-bottom: 22px;
}
.single-awards .content .title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 21px;
}
.single-awards .content p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0px;
  border: 1px solid #273b71;
  border-radius: 50px;
  display: inline-block;
  padding: 2px 25px;
}
.single-awards .content p i {
  margin-right: 8px;
}
.single-awards .content .mybtn2 {
  position: absolute;
  display: block;
  height: 50px;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.4);
}
.single-awards:hover {
  border-color: #681f40;
  transform: translateY(-10px);
}

/* Awards Area End */
/*  How Play Area Start */
.how-play {
  padding: 112px 0px 593px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.how-play .section-heading {
  margin-bottom: 59px;
}

.single-h-play {
  text-align: center;
}
.single-h-play img {
  margin-bottom: 25px;
  display: inline-block;
  height: 100px;
}
.single-h-play .steps {
  font-size: 14px;
  color: #fa009f;
  text-transform: uppercase;
}
.single-h-play .steps i {
  margin-left: 8px;
}
.single-h-play .title {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  text-transform: uppercase;
}

/*  How Play Area End */
/*  Affiliate Process area Start */
.affiliate-process {
  padding: 0px 0px 112px;
  background: #0b122e;
  margin-top: -80px;
}

.single-process {
  padding: 40px 40px 39px;
  background: #141e47;
  text-align: center;
  position: relative;
  transition: all 0.3s ease-in;
}
.single-process img {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 21px;
}
.single-process .title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 14px;
}
.btn-success {
  background-color: #36cb20;
}
.button-wrapper {
  display: flex;
  justify-content: end;
  margin: 16px;
}

.btn {
  font-size: 20px;
  font-weight: bold;
}
@media screen and (max-width: 991px) {
  .section-heading .subtitle {
    font-size: 28px;
  }
  .section-heading .text {
    font-size: 16px;
  }
  .single-turnaments .left-area {
    padding: 42px 36px;
  }
  .single-turnaments .left-area .single-play .contant {
    padding-top: 30px;
  }
}
@media screen and (max-width: 451px) {
  .loader-element {
    left: 25%;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=PlayfairDisplay);
@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.nft-card {
  position: relative;
  display: inline-block;
  width: 374px;
  margin: 20px;
  color: #232323;
  /* when stop */
  /* when stop */
  /* when stop */
}
.nft-card:hover .nft-card--content {
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
}
.nft-card.nft-card--locked .nft-card--content {
  color: #232323;
}
.nft-card.nft-card--locked .nft-card--content:before,
.nft-card.nft-card--locked .nft-card--content:after {
  display: none;
}
.nft-card.nft-card--locked .nft-card--content img {
  pointer-events: none;
  -webkit-filter: blur(15px);
  /* Safari 6.0 - 9.0 */
  filter: blur(15px);
  opacity: 35%;
}
.nft-card.nft-card--collection {
  margin-top: 30px;
}
.nft-card.nft-card--collection:hover:before,
.nft-card.nft-card--collection:hover:after {
  top: -12px;
  left: 10.5%;
  height: 12px;
  width: 79%;
}
.nft-card.nft-card--collection:hover:after {
  top: -24px;
  left: 15.5%;
  height: 12px;
  width: 69%;
}
.nft-card.nft-card--collection:hover .nft-card--header:before {
  top: -36px;
  left: 20.5%;
  height: 12px;
  width: 59%;
}
.nft-card.nft-card--collection:before,
.nft-card.nft-card--collection:after {
  content: '';
  position: absolute;
  top: -10px;
  left: 10%;
  display: block;
  height: 10px;
  width: 80%;
  border-radius: 20px 20px 0 0;
  background: #000;
  opacity: 15%;
  transition: 0.3s;
}
.nft-card.nft-card--collection:after {
  top: -20px;
  width: 70%;
  left: 15%;
  opacity: 10%;
}
.nft-card.nft-card--collection .nft-card--header {
  padding: 0;
}
.nft-card.nft-card--collection .nft-card--header:before {
  display: block;
  content: '';
  position: absolute;
  top: -30px;
  left: 20%;
  display: block;
  height: 10px;
  width: 60%;
  border-radius: 20px 20px 0 0;
  background: #000;
  opacity: 5%;
  transition: 0.3s;
}
.nft-card.nft-card--collection .nft-card--header:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 1px;
  width: 1px;
  border-top: 45px solid #f9f9f9;
  border-right: 45px solid #f9f9f9;
  border-left: 45px solid transparent;
  border-bottom: 45px solid transparent;
  border-radius: 0 30px 0 0;
  background: transparent;
}
.nft-card.nft-card--collection .nft-card--content {
  background: #000;
}
.nft-card.nft-card--collection .nft-card--content:before {
  content: '\f02e';
  position: absolute;
  height: auto;
  width: auto;
  top: 10px;
  right: 30px;
  z-index: 1;
  font-family: FontAwesome;
  font-size: 40px;
  color: #e7007c;
  background: none;
}
.nft-card.nft-card--collection .nft-card--content:after {
  display: none;
}
.nft-card.nft-card--collection .nft-card--content .nft-title {
  position: absolute;
  top: 50%;
  right: 60px;
  left: 60px;
  z-index: 10;
  height: 100px;
  margin-top: -50px;
  text-align: center;
}
.nft-card.nft-card--collection .nft-card--content .nft-title h3 {
  margin: 0;
  padding: 6px 20px;
  font-family: Roboto;
  font-size: 28px;
  line-height: 1.2;
  text-transform: uppercase;
}
.nft-card.nft-card--collection .nft-card--content .nft-title h3 span {
  display: block;
  font-size: 18px;
  font-weight: normal;
  text-transform: none;
}
.nft-card.nft-card--collection .nft-card--content img {
  opacity: 75%;
}
.nft-card.nft-card--category {
  margin-top: 30px;
}
.nft-card.nft-card--category:hover:before,
.nft-card.nft-card--category:hover:after {
  top: -12px;
  left: 10.5%;
  height: 12px;
  width: 79%;
}
.nft-card.nft-card--category:hover:after {
  top: -24px;
  left: 15.5%;
  height: 12px;
  width: 69%;
}
.nft-card.nft-card--category:hover .nft-card--header:before {
  top: -36px;
  left: 20.5%;
  height: 12px;
  width: 59%;
}
.nft-card.nft-card--category:before,
.nft-card.nft-card--category:after {
  content: '';
  position: absolute;
  top: -10px;
  left: 10%;
  display: block;
  height: 10px;
  width: 80%;
  border-radius: 20px 20px 0 0;
  background: #000;
  opacity: 15%;
  transition: 0.3s;
}
.nft-card.nft-card--category:after {
  top: -20px;
  width: 70%;
  left: 15%;
  opacity: 10%;
}
.nft-card.nft-card--category .nft-card--header {
  padding: 0;
}
.nft-card.nft-card--category .nft-card--header:before {
  display: block;
  content: '';
  position: absolute;
  top: -30px;
  left: 20%;
  display: block;
  height: 10px;
  width: 60%;
  border-radius: 20px 20px 0 0;
  background: #000;
  opacity: 5%;
  transition: 0.3s;
}
.nft-card.nft-card--category .nft-card--header:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 1px;
  width: 1px;
  border-top: 45px solid #f9f9f9;
  border-right: 45px solid #f9f9f9;
  border-left: 45px solid transparent;
  border-bottom: 45px solid transparent;
  border-radius: 0 30px 0 0;
  background: transparent;
}
.nft-card.nft-card--category .nft-card--content {
  height: 210px;
  background: #000;
}
.nft-card.nft-card--category .nft-card--content:before {
  content: '\f02e';
  position: absolute;
  height: auto;
  width: auto;
  top: 10px;
  right: 30px;
  z-index: 1;
  font-family: FontAwesome;
  font-size: 40px;
  color: #e7007c;
  background: none;
}
.nft-card.nft-card--category .nft-card--content:after {
  display: none;
}
.nft-card.nft-card--category .nft-card--content .nft-title {
  position: absolute;
  top: 50%;
  right: 60px;
  left: 60px;
  z-index: 10;
  height: 66px;
  margin-top: -33px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(15px);
}
.nft-card.nft-card--category .nft-card--content .nft-title h3 {
  margin: 0;
  padding: 6px 20px;
  font-family: Roboto;
  font-size: 28px;
  line-height: 1.2;
  text-transform: uppercase;
}
.nft-card.nft-card--category .nft-card--content .nft-title h3 span {
  display: block;
  font-size: 18px;
  font-weight: normal;
  text-transform: none;
}
.nft-card.nft-card--category .nft-card--content img {
  float: left;
  width: 50%;
  height: 50%;
  opacity: 75%;
}
.nft-card.nft-card--upload {
  margin-top: 30px;
}
.nft-card.nft-card--upload:hover:before,
.nft-card.nft-card--upload:hover:after {
  top: -12px;
  left: 10.5%;
  height: 12px;
  width: 79%;
}
.nft-card.nft-card--upload:hover:after {
  top: -24px;
  left: 15.5%;
  height: 12px;
  width: 69%;
}
.nft-card.nft-card--upload:hover .nft-card--header:before {
  top: -36px;
  left: 20.5%;
  height: 12px;
  width: 59%;
}
.nft-card.nft-card--upload:before,
.nft-card.nft-card--upload:after {
  content: '';
  position: absolute;
  top: -10px;
  left: 10%;
  display: block;
  height: 10px;
  width: 80%;
  border-radius: 20px 20px 0 0;
  background: #000;
  opacity: 15%;
  transition: 0.3s;
}
.nft-card.nft-card--upload:after {
  top: -20px;
  width: 70%;
  left: 15%;
  opacity: 10%;
}
.nft-card.nft-card--upload .nft-card--header {
  padding: 0;
}
.nft-card.nft-card--upload .nft-card--header:before {
  display: block;
  content: '';
  position: absolute;
  top: -30px;
  left: 20%;
  display: block;
  height: 10px;
  width: 60%;
  border-radius: 20px 20px 0 0;
  background: #000;
  opacity: 5%;
  transition: 0.3s;
}
.nft-card.nft-card--upload .nft-card--header:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  height: 1px;
  width: 1px;
  border-top: 45px solid #f9f9f9;
  border-right: 45px solid #f9f9f9;
  border-left: 45px solid transparent;
  border-bottom: 45px solid transparent;
  border-radius: 0 30px 0 0;
  background: transparent;
}
.nft-card.nft-card--upload .nft-card--content {
  color: #999;
  background: #fff;
}
.nft-card.nft-card--upload .nft-card--content:before {
  content: '\f02e';
  position: absolute;
  height: auto;
  width: auto;
  top: 10px;
  right: 30px;
  z-index: 1;
  font-family: FontAwesome;
  font-size: 40px;
  color: #e7007c;
  background: none;
}
.nft-card.nft-card--upload .nft-card--content:after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  height: auto;
  width: auto;
  border-radius: 10px;
  border: 2px dashed #ddd;
  background: none;
}
.nft-card.nft-card--upload .nft-card--content .nft-description i {
  font-size: 32px;
}
.nft-card.nft-card--upload .nft-card--content .nft-description h3 {
  margin: 10px 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.nft-card.nft-card--upload .nft-card--content .nft-description h3 span {
  display: block;
  font-size: 12px;
  font-weight: none;
}
.nft-card.nft-card--upload .nft-card--content img {
  opacity: 75%;
}
.nft-card.nft-card--music .nft-card--content:after {
  display: none;
}
.nft-card.nft-card--music .nft-card--content .nft-qrcode {
  color: #232323;
}
.nft-card .nft-card--header {
  padding: 10px 20px;
  transition: 0.3s;
}
.nft-card .nft-card--content {
  position: relative;
  width: 100%;
  height: 500px;
  color: #fff;
  background: #fff;
  border: 10px solid #fff;
  border-radius: 30px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.3s;
}
.nft-card .nft-card--content:before,
.nft-card .nft-card--content:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  height: 155px;
  width: 100%;
}
.nft-card .nft-card--content:before {
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0));
}
.nft-card .nft-card--content:after {
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
}
.nft-card .nft-card--content .nft-action {
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 30px;
  font-size: 16px;
  font-family: Roboto;
  color: inherit;
}
.nft-card .nft-card--content .nft-action span {
  margin-left: 10px;
}
.nft-card .nft-card--content .nft-action i {
  font-size: 18px;
  margin-left: 5px;
}
.nft-card .nft-card--content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.nft-card .nft-card--content video {
  width: 100%;
  height: 122%;
  margin-top: -15%;
  object-fit: cover;
}
.nft-card .nft-card--content .nft-badge {
  position: absolute;
  z-index: 10;
  bottom: 30px;
  left: 30px;
}
.nft-card .nft-card--content .nft-qrcode {
  position: absolute;
  z-index: 10;
  bottom: 30px;
  left: 30px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: inherit;
}
.nft-card .nft-card--content .nft-qrcode span {
  display: block;
  font-size: 14px;
  font-weight: normal;
  opacity: 75%;
}
.nft-card .nft-card--content .nft-qrcode img,
.nft-card .nft-card--content .nft-qrcode .fa-qrcode {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  font-size: 50px;
}
.nft-card .nft-card--content .nft-unlock {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 10;
}
.nft-card .nft-card--content .nft-description {
  position: absolute;
  top: 50%;
  right: 60px;
  left: 60px;
  z-index: 10;
  height: 100px;
  margin-top: -50px;
  text-align: center;
}
.nft-card .nft-card--footer {
  padding: 10px 20px;
  transition: 0.3s;
}
.nft-card .nft-card--footer .nft-time {
  float: left;
}
.nft-card .nft-card--footer .nft-price {
  float: right;
}
.nft-card .wave {
  position: absolute;
  height: 900px;
  width: 900px;
  opacity: 0.5;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -75%;
  margin-top: 100%;
  background: radial-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.15));
  background: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.25)
  );
}
.nft-card .wave:nth-child(2) {
  top: 5px;
}
.nft-card .wave:nth-child(3) {
  top: 10px;
}
.nft-card:hover .wave {
  border-radius: 40%;
  /* animation: wave 3000ms infinite linear; */
}
.nft-card .wave {
  border-radius: 40%;
  animation: wave 7s infinite linear;
}
.nft-card:hover .wave:nth-child(2) {
  animation-duration: 4000ms;
}
.nft-card .wave:nth-child(2) {
  animation-duration: 5s;
}
.nft-card:hover .wave:nth-child(3) {
  animation-duration: 5000ms;
}
.nft-card .wave:nth-child(3) {
  animation-duration: 3s;
}
.nft-card.nft-card--compact {
  transition: 0.3s ease;
}
.nft-card.nft-card--compact:hover {
  transform: scale(1.01);
}
.nft-card.nft-card--compact:hover .nft-card--content {
  transform: scale(1);
  transition: 0s;
}
.nft-card.nft-card--compact.nft-card--locked .nft-card--header .nft-user {
  color: #232323;
}
.nft-card.nft-card--compact.nft-card--locked .nft-card--footer .nft-time,
.nft-card.nft-card--compact.nft-card--locked .nft-card--footer .nft-price {
  color: #232323;
}
.nft-card.nft-card--compact .nft-card--header {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 10;
  padding: 0;
}
.nft-card.nft-card--compact .nft-card--header .nft-user {
  color: #fff;
}
.nft-card.nft-card--compact .nft-card--header .nft-user span {
  opacity: 75%;
}
.nft-card.nft-card--compact .nft-card--footer {
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 10;
  padding: 0;
  color: #fff;
}
.nft-card.nft-card--compact .nft-card--footer .nft-time,
.nft-card.nft-card--compact .nft-card--footer .nft-price {
  float: none;
  text-align: right;
}
.nft-card.nft-card--compact .nft-card--footer .nft-time span,
.nft-card.nft-card--compact .nft-card--footer .nft-price span {
  opacity: 75%;
}
.nft-payment {
  position: relative;
  display: inline-block;
  width: 374px;
  margin: 20px;
  color: #232323;
  transition: 0.3s;
}
.nft-payment:hover .nft-payment--content {
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
}
.nft-payment.nft-payment--mini .nft-payment--content {
  height: 155px;
  padding: 40px 30px;
  background: linear-gradient(to bottom right, #4bc9e0, #0884ba);
}
.nft-payment.nft-payment--mini .nft-payment--content:before,
.nft-payment.nft-payment--mini .nft-payment--content:after {
  top: -50%;
  left: 40%;
}
.nft-payment.nft-payment--mini .nft-payment--content:before {
  margin: 30% 0 0 -50%;
}
.nft-payment.nft-payment--mini .nft-payment--content .nft-info {
  float: right;
  position: relative;
  top: auto;
  left: auto;
  margin-top: 5px;
  text-align: right;
}
.nft-payment.nft-payment--mini .nft-payment--content .nft-info:before {
  top: -65%;
  left: -45%;
}
.nft-payment.nft-payment--mini .nft-payment--content .nft-balance {
  float: left;
  position: relative;
  bottom: auto;
  left: auto;
}
.nft-payment .nft-payment--header {
  padding: 0;
}
.nft-payment .nft-payment--content {
  position: relative;
  width: 100%;
  height: 500px;
  color: #fff;
  background: #fff;
  border: 10px solid #fff;
  border-radius: 30px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.3s;
  background: linear-gradient(to bottom right, #b24be0, #ba0808);
}
.nft-payment .nft-payment--content:before,
.nft-payment .nft-payment--content:after {
  content: '';
  position: absolute;
  top: 10%;
  left: 40%;
  z-index: 10;
  background: #fff;
  width: 500px;
  height: 500px;
  border-radius: 100%;
  opacity: 5%;
  pointer-events: none;
}
.nft-payment .nft-payment--content:before {
  margin: 50% 0 0 -50%;
}
.nft-payment .nft-payment--content .nft-info {
  position: absolute;
  top: 30px;
  left: 30px;
  color: #fff;
  font-size: 20px;
  font-family: Roboto;
  text-transform: uppercase;
  text-align: left;
}
.nft-payment .nft-payment--content .nft-info:before {
  content: '';
  position: absolute;
  top: 155%;
  left: 75%;
  z-index: 10;
  background: #fff;
  width: 500px;
  height: 500px;
  border-radius: 100%;
  opacity: 5%;
  pointer-events: none;
}
.nft-payment .nft-payment--content .nft-info i {
  display: block;
  margin-bottom: 40px;
  color: inherit;
  font-size: 42px;
  opacity: 50%;
}
.nft-payment .nft-payment--content .nft-info span {
  display: block;
  margin-top: 3px;
  color: inherit;
  font-size: 14px;
  opacity: 50%;
}
.nft-payment .nft-payment--content .nft-balance {
  position: absolute;
  left: 30px;
  bottom: 30px;
  color: #fff;
  text-align: left;
  font-size: 32px;
}
.nft-profile {
  display: flex;
  justify-content: center;
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 20px;
  color: #232323;
  transition: 0.3s;
}
.nft-profile:hover .nft-profile--content {
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
}
.nft-profile .nft-profile--header {
  padding: 0;
}
.nft-profile .nft-profile--content {
  margin: 0 auto;
  position: relative;
  width: 50%;
  height: 800px;
  color: #fff;
  background: #fff;
  border: 10px solid #fff;
  border-radius: 30px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.3s;
  background: #fff;
  outline: 1px solid #ddd;
}

.nft-profile .nft-profile--content img {
  width: 100%;
  height: 45%;
  object-fit: cover;
}
.nft-profile .nft-profile--content .nft-user {
  position: absolute;
  top: 20%;
  left: 44%;
  margin: -91px 0 0 -91px;
}
.nft-profile .nft-profile--content .nft-user img {
  width: 300px;
  height: 300px;
  border: 16px solid #fff;
  margin: 0;
}
.nft-profile .nft-profile--content .nft-info {
  margin-top: 100px;
  text-align: center;
}
.nft-profile .nft-profile--content .nft-info h3 {
  font-family: Roboto;
  color: #232323;
  font-wight: bold;
  font-size: 20px;
  text-transform: uppercase;
}
.nft-profile .nft-profile--content .nft-info h3 span {
  display: block;
  font-size: 16px;
  text-transform: none;
  opacity: 50%;
  margin-top: 5px;
}
.nft-profile .nft-profile--content .nft-action {
  text-align: center;
}
.nft-user {
  min-width: 200px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
}
.nft-user span {
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  opacity: 45%;
}
.nft-user img {
  float: left;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 100%;
  border: 2px solid #fff;
  object-fit: cover;
}
.nft-time {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
}
.nft-time span {
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  opacity: 45%;
}
.nft-price,
.nft-balance {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  text-align: right;
}
.nft-price span,
.nft-balance span {
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  opacity: 45%;
}
.nft-price i,
.nft-balance i {
  font-weight: normal;
}
.nft-audiotrack {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 10;
  height: 50px;
  width: 62%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.35);
  overflow: hidden;
}
.nft-audiotrack img {
  min-height: 500px;
  min-width: 374px;
  object-fit: cover;
  -webkit-filter: blur(15px);
  /* Safari 6.0 - 9.0 */
  filter: blur(15px);
  opacity: 75%;
}
.btn {
  position: relative;
  height: 50px;
  padding: 7px 30px;
  margin: 5px;
  color: #232323;
  font-weight: bold !important;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 10px;
  outline: none;
  border: none;
  transition: 0.3s;
}
.btn.btn--primary {
  color: #fff;
  background: linear-gradient(45deg, #e7007c, #bf0093);
  box-shadow: 0 4px 20px rgba(231, 0, 124, 0.35);
}
.btn.btn--primary:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100px;
  height: 50px;
  background: #000;
}
.btn.btn--primary:hover {
  cursor: pointer;
  transform: scale(0.99);
  box-shadow: 0 0px 0px rgba(231, 0, 124, 0.35);
}
.btn.btn--secondary {
  color: #e7007c;
  background: none;
  border: 1px solid #e7007c;
}
.btn.btn--icon {
  padding: 7px 22px;
  color: #232323;
  background: #f4f4f4;
}
p {
  font-size: 16px;
  font-family: Roboto;
  line-height: 21px;
  color: #757575;
}
.badge {
  padding: 4px 10px;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  color: #232323;
  background: #fff;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .nft-profile .nft-profile--content {
    width: 100%;
    border-radius: 0; /* Remove border-radius for mobile */
    border: none; /* Remove border for mobile */
    padding: 20px; /* Add padding for better spacing */
    box-sizing: border-box;
    transition: none; /* Remove transition for mobile */
    outline: none; /* Remove outline for mobile */
  }
  .nft-profile .nft-profile--content .nft-user {
    left: 30% !important;
  }

  .nft-profile {
    max-width: 100%;
    width: 88%;
  }
}
